:root {
  --mainGold: #579599;
  --mainDark: rgb(67, 62, 77);
  --mainBlue: #d97ca2;
  --lightBlue: rgb(202, 227, 216);
  --brightPink: #cc2b6e;
}

.App {
  text-align: center;
}

a {
  text-decoration: none !important;
}

h3,h5 {
  font-family: 'Pattaya', sans-serif !important;
  font-weight: bold;
}

p {
  font-family: sans-serif;
}